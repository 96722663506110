import 'jquery';
import {
	jarallax
} from 'jarallax';
import {
    magnificPopup
} from 'magnific-popup';
import {
    bootstrapSlider
} from 'bootstrap-slider';

jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.6,
});

( function ( $ ) {

	$( 'document' ).ready( function () {

		const
			$pricing_table 	= $( '.reg-pricing-plans__table__item', '.reg-pricing-plans__table' ),
			$changed_blog_qty = $( '#changed-blog-qty' );

		// Pricing
		$pricing_table.on( 'click', 'a.btn', function ( e ) {
			e.preventDefault();
			const
				$this = $( this ),
				$pricing_item = $this.parents( '.reg-pricing-plans__table__item' );
			$pricing_table.removeClass( 'selected' ).find( 'a.btn' ).text( 'select plan' );
			$this.text( 'selected' );
			$pricing_item.addClass( 'selected' );
			$( '#total-blog-qty' ).text( $( '.get-price-value', $pricing_item ).text() );
			$( '#total-blog-period' ).text( $( '.get-price-period', $pricing_item ).text() );
		} ).eq( 1 ).find( 'a.btn' ).trigger( 'click' );

		// Range slider
		$( '.changed-blog-qty-slider' )
			.bootstrapSlider();

		$( '.changed-blog-qty-slider' ).on( 'change', function () {
			$( '#blog-qty-input' ).val( $( this ).val() );
		} );

		$( "body" ).on( 'click', '.changed-blog-qty', function() { 
			$( this ).magnificPopup({
				type: 'inline',
				preloader: false,
				removalDelay: 200,
				closeBtnInside: false,
				callbacks: {

					beforeOpen: function() {

						this.st.mainClass = this.st.el.attr('data-effect');

					},

					close: function () {

						$( 'button.btn', $.magnificPopup.instance.currItem.src ).unbind( 'click' );

					},
					
					open: function () {

						const
							promise = () => new Promise( ( resolve, reject ) => {

								const
									qty = parseInt( $( '#blog-qty-input' ).val(), 10 ),
									ammount = qty * 2;

								$( '#change-blog-qty' ).text( qty );
								$( '#changed-blog-qty' ).text( ammount );
								$( '#total-blog-qty' ).text( ammount );

								console.log( 'make some magic...' );

								resolve();

							} );

						$( 'button.btn', $.magnificPopup.instance.currItem.src ).on( 'click', function () {

							promise().then( result => {

								$.magnificPopup.instance.close();

							} );

						} );

					}
				}
			} ).magnificPopup( 'open' );

		} );
	} );

} )( jQuery );
